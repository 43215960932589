import React from 'react'
import logo from "../images/logo.png";

export function Nav() {
  return (
    <nav className="navbar bg-primary">
      <a className="navbar-brand" href="#">
        <img src={logo} loading="lazy" />
      </a>
    </nav>
  )
}
