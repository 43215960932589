import React from 'react'
import wiffi01 from "../images/wiffi_01.png";
import wiffi02 from "../images/wiffi_02.png";

export function Form({ ssid, pass, onChangeSsid, onChangePass}) {
  return (
    <div className="form">
      <div className="media mt-4">
        <img src={wiffi01} className="mr-3" width={62} alt="うぃふぃーちゃん"/>
        <div className="media-body">
          <div className="balloon">
            <input
              type="text"
              className="form-control"
              value={ssid}
              onChange={onChangeSsid}
              placeholder="SSIDをいれてね"
            />
          </div>
        </div>
      </div>

      <div className="media my-3">
        <img src={wiffi02} className="mr-3" width={62} alt="うぃふぃーちゃん"/>
        <div className="media-body">
          <div className="balloon">
            <input
              type="text"
              className="form-control"
              value={pass}
              onChange={onChangePass}
              placeholder="パスワードをいれてね"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
