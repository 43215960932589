import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF, faTwitter} from "@fortawesome/free-brands-svg-icons";

export function Footer() {
  function handleClickPrint() {
    if (typeof window !== "undefined") {
      window.print();
    }
  }

  return (
    <footer>
      <button className="btn btn-green btn-block py-3" onClick={handleClickPrint}>
        <FontAwesomeIcon icon={faPrint} width={16} height={16} />
        <span className="ml-2">いんさつする</span>
      </button>

      <p className="my-4 text-center text-white">「wiffy card」は置くだけでお店の混雑度がわかる「<a href="https://fi-inc.me">wiffy</a>」が提供しています。</p>

      <div className="text-center">
        <a className="btn btn-light btn-circle mx-2" href="https://twitter.com/share?url=https://card.fi-inc.me&hashtags=wiffy,うぃふぃーちゃん" target="_blank" rel="noopener">
          <FontAwesomeIcon icon={faTwitter} width={16} height={16} />
        </a>

        <a className="btn btn-light btn-circle mx-2" href="http://www.facebook.com/share.php?u=https://card.fi-inc.me" target="_blank" rel="noopener">
          <FontAwesomeIcon icon={faFacebookF} width={16} height={16} />
        </a>
      </div>
    </footer>
  )
}
