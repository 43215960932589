import React from 'react'
import headline from "../images/headline.png";

export function Headline() {
  return (
    <div className="headline">
      <div className="text-center">
        <h1 className="h1">
          <img src={headline} alt="うぃふぃーちゃんの wiffy card"/>
        </h1>
        <p className="description mt-2">わいふぁいにつながるQRこーどがつくれるよ</p>
      </div>
    </div>
  )
}
