import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import {Card, Footer, Form, Headline, Nav} from "../components";

import "../styles/styles.scss";
import favicon from "../images/favicon.png";
import ogp from "../images/ogp.jpg";
import QRCode from "qrcode.react";

export default function Root() {
  const [ssid, setSsid] = useState("");
  const [pass, setPass] = useState("");
  const qrcode = `WIFI:T:WPA;S:${ssid};P:${pass};;`;

  useEffect(() => {
    if (typeof window !== "undefined" && window.location.hash) {
      const regex = new RegExp("#/([^/]+)/([^/]+)");
      const match = regex.exec(window.location.hash);
      if (match !== null) {
        setSsid(match[1]);
        setPass(match[2]);
      }
    }
  });

  function handleChangeSsid(event) {
    setSsid(event.target.value);
  }

  function handleChangePass(event) {
    setPass(event.target.value);
  }

  return (
    <>
      <Helmet>
        <title>うぃふぃーちゃんの wiffy card</title>

        <meta name="description" content="わいふぁいにつながるQRこーどがつくれる【うぃふぃーちゃんのwiffy card】" />
        <meta name="keywords" content="うぃふぃー,WI-FI,WI-FIcard,QRコード" />
        <meta property="og:type" content="Websites" />
        <meta property="og:description" content="わいふぁいにつながるQRこーどがつくれる【うぃふぃーちゃんのwiffy card】" />
        <meta property="og:title" content="うぃふぃーちゃんのwiffy card" />
        <meta property="og:url" content="https://card.fi-inc.me" />
        <meta property="og:image" content={ogp} />
        <meta property="og:site_name" content="うぃふぃーちゃんのwiffy card" />
        <meta property="og:locale" content="ja_JP" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="わいふぁいにつながるQRこーどがつくれる【うぃふぃーちゃんのwiffy card】" />
        <meta name="twitter:title" content="うぃふぃーちゃんのwiffy card" />
        <meta name="twitter:url" content="https://card.fi-inc.me" />
        <meta name="twitter:image" content="https://card.fi-inc.me/images/ogp.jpg" />
        <link href={favicon} rel="icon" type="image/png" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-138169609-2"
        />
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-138169609-2');
        `}</script>
      </Helmet>

      <div className="wrap d-flex align-items-end flex-column">
        <section className="header">
          <Nav />
        </section>

        <section className="main container">
          <Headline />

          <div className="d-flex justify-content-center">
            <Form
              ssid={ssid}
              pass={pass}
              onChangeSsid={handleChangeSsid}
              onChangePass={handleChangePass}
            />
          </div>

          <div className="mx-auto d-flex justify-content-center align-items-center">
            <QRCode className="qrcode mr-3" value={qrcode} size={240} />
          </div>
        </section>

        <section className="footer mt-auto">
          <Footer />
        </section>
      </div>

      <div id="print-area">
        <Card ssid={ssid} pass={pass} qrcode={qrcode} />
      </div>
    </>
  );
}
