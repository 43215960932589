import React from 'react'
import QRCode from "qrcode.react";

import printLogo from "../images/print_logo.png";
import printTitle from "../images/print_title.png";
import printWiffi from "../images/print_wiffi.png";

export function Card({ssid, pass, qrcode}) {
  return (
    <div className="wifi-card">

      <div className="wifi-card-header">
        <img className="wifi-card-title" src={printTitle} />
      </div>

      <div className="wifi-card-body">
        <img className="wifi-card-logo" src={printLogo} />

        <div className="wifi-card-qr">
          <QRCode className="qrcode mr-3" value={qrcode} size={160} />
        </div>

        <div className="wifi-card-login">
          <div>
            <span className="badge badge-green">SSID</span>
            <p>{ssid}</p>
          </div>

          <div>
            <span className="badge badge-green">PASSWORD</span>
            <p>{pass}</p>
          </div>
        </div>

        <img src={printWiffi} className="wifi-card-wiffi" />
      </div>
    </div>
  )
}
